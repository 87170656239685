import React from "react"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Slider from "react-slick"

import styled from "styled-components"
import Div100vh from "react-div-100vh"
import { device } from "../device"
/* 
import phone from "../../images/contactos/phone.svg"
import mail from "../../images/contactos/mail.svg" */

const HomeScreen = ({ data, sidebar }) => {
  const { t } = useTranslation(["alimentares"])

  const settings = {
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    pauseOnHover: false,
    swipeToSlide: false,
    autoplay: true,
    infinite: true,
  }

  return (
    <StyledHome>
      {sidebar}
      <Slider {...settings}>
        {data.images.map((data, i) => (
          <Img
            fluid={data.image.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            className="background"
          />
        ))}
      </Slider>
      <div className="background-image">
        <Img
          fluid={data.background.image.childImageSharp.fluid}
          imgStyle={{ objectFit: "cover" }}
        />
      </div>
      <div className="info">
        <div className="first">
          {/* <h2 className={font.nSR + " alTitle"}>{t("produtos")}</h2> */}
          <p className={font.nSR}>{t("produtosTxt")}</p>
          <br />
          <p>{t("produtosList")}</p>
        </div>

        <div className="second">
          <div className="btn" style={{ marginTop: "20px" }}>
            <a
              href="/contactos"
              alt="Entre em contacto"
              rel="noopener noreferrer nofollow"
            >
              <h3 className={font.nSR}>Entre em contacto</h3>
            </a>
          </div>
          {/* <div className="icons">
            <a href="tel:+351234329530">
              <img src={phone} alt="Telefone" className="icon" />
            </a>
            <a href="mailto:saven@saven.pt">
              <img src={mail} alt="Email" className="icon" />
            </a>
          </div>
          <a href="tel:+351234329530">
            <h3 className={font.nSR}>Tel. {t("tel")}</h3>
          </a>
          <a href="tel:+351234329531">
            <h3 className={font.nSR}>Fax. {t("fax")}</h3>
          </a>
          <a href="mailto:saven@saven.pt">
            <h3 className={font.nSR}>{t("email")}</h3>
          </a> */}
        </div>
      </div>
    </StyledHome>
  )
}

export default HomeScreen

const StyledHome = styled(Div100vh)`
  width: 100%;
  position: relative;
  z-index: 2;

  .background-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 60%;
    width: 100%;
    z-index: -1;

    & .gatsby-image-wrapper {
      height: 100%;
    }

    @media ${device.desktop} {
      min-width: 84.35%;
      margin-left: 15.65%;
      height: 65.5%;
    }
  }

  .slick-slider {
    margin-top: 50px;

    @media ${device.mobile} {
      min-width: 100%;
      height: 60%;
      position: relative;
    }
    @media ${device.desktop} {
      margin-top: 0;
      min-width: 84.35%;
      margin-left: 15.65%;
      height: 65%;
      position: relative;
    }

    /* &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #362921;
      opacity: 0.2;
    } */

    .slick-list {
      height: 100%;
    }
    .slick-track {
      display: flex;
      height: 100%;
    }
    .slick-slide > div {
      height: 100%;
    }
    .background {
      height: 100%;

      @media ${device.mobile} {
        & img {
          object-position: 50% 50% !important;
        }
      }

      @media ${device.desktop} {
        & img {
          object-position: 50% 35% !important;
        }
      }
    }
  }

  .info {
    color: #3e3028;
    padding: 50px 25px;
    position: absolute;
    background: white;
    text-align: center;

    @media ${device.mobile} {
      min-height: 40%;
    }
    @media ${device.desktop} {
      min-width: 84.35%;
      margin-left: 15.65%;
      min-height: 35%;
      height: 35%;
      margin-left: 15.65%;
      top: 65%;
    }

    .first,
    .second {
      @media ${device.desktop} {
        margin: 0 25px;
      }
    }

    .first {
      @media ${device.desktop} {
        width: auto;
        margin: 0 auto;
      }
    }

    .second {
      @media ${device.desktop} {
        max-width: auto;
      }
    }

    .alTitle {
      font-size: 25px;
      margin-bottom: 15px;

      @media ${device.desktop} {
        font-size: 54px;
      }
    }

    p {
      font-size: 18px;
      letter-spacing: normal;

      &:last-of-type {
        font-size: 18px;
        font-weight: bold;
        width: 80%;
        margin: 0 auto;
      }

      @media ${device.desktop} {
        font-size: 20px;

        &:last-of-type {
          font-size: 25px;
          width: 60%;
        }
      }
    }

    a {
      h3 {
        font-size: 20px;

        @media ${device.desktop} {
          font-size: 32px;
        }
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      @media ${device.desktop} {
        margin: 30px 0;
        margin-bottom: 15px;
      }

      img {
        margin-right: 40px;
      }
    }

    .btn {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 80px auto 0;
      max-width: 400px;

      @media ${device.mobileXL} {
        width: 70%;
      }

      & a {
        background: transparent;
        border-radius: 0;
        border: 1px solid #3e3028;
        user-select: none;
        outline: none;
        margin-bottom: 3vh;
        height: 50px;
        color: #3e3028;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-color: #3e3028;
          color: white;
        }
      }

      h3 {
        font-size: 20px;
        letter-spacing: 0;

        @media ${device.tablet} {
          font-size: 26px;
        }
      }
    }
  }
`
