import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Sidebar from "../components/sidebar/sidebar"
import Alimentares from "../components/homescreen/homeScreenAlimentares"
import SidebarMobile from "../components/sidebar/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const AlimentaresPage = ({ data }) => {
  const breakpoints = useBreakpoint()

  return (
    <Layout color="#150e04">
      <SEO title="Alimentares" />
      <Alimentares
        data={data.alimentaresJson}
        sidebar={
          !breakpoints.mobile ? (
            <Sidebar white="isWhite" switchMenu="left" />
          ) : (
            <SidebarMobile />
          )
        }
      />
    </Layout>
  )
}

export default AlimentaresPage

export const Json = graphql`
  query alimentares {
    alimentaresJson {
      images {
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      background {
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
